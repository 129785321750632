<template>
  <div class="home-container">
    <!-- <div class="header linear-bg" style="height: auto;" v-if="this.$store.getters.getBaseInfo.ico">
      <img
                class=""
                style="height: 50px;padding: 0 15px;display: block;"
                :src="
                  this.$store.getters.getBaseInfo.ico"
              />
              <div style="flex: 1;"></div>
              <van-icon name="service-o" size="24" color="#fff" style="margin-right: 10px;" v-if="iskefu == '1'" @click="toKefu" />
    </div> -->

    <van-nav-bar class="nav-bar">
      <template #left>
        <span class="appname">

          {{ $store.getters.getBaseInfo.name }}
        </span>
      </template>

      <template #right>
        <span class="appname" @click="addr_popup = true"><van-icon name="location-o" size="20" color="#ffffff" />{{
          $t("nHome.checked_address")
          }}</span>
      </template>
    </van-nav-bar>

    <div class="mask" v-if="isLoading && video_login == 1 && !token">
      <van-loading type="spinner" size="80px" />
    </div>
    <!-- <div class="linear-bg"></div> -->
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="noticeContent">
        <div class="sm">
          <div>
            <div class="left">
              <p>
                {{ $t('zise.meiti') }}
              </p>

              <p>
                
                {{ $t("zise.home_txt1") }}
              </p>

              <p>
                
                {{ $t("zise.home_txt2") }}
              </p>
              <!-- <span>
                {{ $t('zise.fuli') }}
              </span> -->
            </div>
            <div class="right">
              <p>
                {{ $t('zise.ziyuan') }}
              </p>
            </div>
          </div><span>
            {{ $t('zise.jiaru') }}

          </span>
        </div>
        <div class="navs">
          <nav><a :class="{ 'active': tabs == 0 }" @click="tabs = 0">
              {{ $t('zise.neitiziyuan') }}
            </a><a :class="{ 'active': tabs == 1 }" @click="tabs = 1">
              {{ $t('zise.fuwutiaokuang') }}
            </a></nav>
        </div>

      </div>
      <div class="item tiaokuan" v-if="tabs == 1">
        <ul>
          <li>
            <div>
              <p>
                {{ $t('zise.pingtaiziyuan') }}
              </p><span>
                {{ $t('zise.pingtaiziyuan1') }}
              </span>
            </div>
            <div>
              <p>
                {{ $t('zise.fuwufanwei') }}
              </p><span>
                {{ $t('zise.fuwufanwei1') }}
              </span>
            </div>
          </li>
        </ul>
      </div>

      <van-pull-refresh v-if="tabs == 0" v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <!-- <div class="hot-title-div" style="margin-bottom: -20px;">
        <div>
          <span>{{$t('index.hot')}}</span>
        </div>
      </div> -->
        <van-list v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
          :finished-text="$t('video.no_more')" @load="onLoad" :loading-text="$t('refresh.loading')">
          <div class="item">
            <ul>
              <li v-for="(v, key) in movielist_1" :key="key" @click="toPlayVideo(v.id)"><i>{{ $t('zise.yiyanzheng')
                  }}</i>
                <div class="left">
                  <p>ID:{{ v.id }}**</p>
                  <div class="ziliao"><span>{{ $t('zise.nichen') }}：{{ v.xuanfei_name }}</span>
                    <span>{{ $t('zise.leixing') }}：{{ v.service }}</span>
                    <span>{{ $t('zise.dizhi') }}：{{ v.address }}</span><span>{{ $t('zise.fuwuxiangmu') }}：{{
                      $t('zise.jihuoxianshi') }}</span>
                  </div>
                  <div class="ft"><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABIRJREFUaEPtWl1oHFUU/s7Miq8aERFFihbRh7agVuhDsS2CL75opWClah+Umuw9s6EiRgsdENQHze7cu6tEReNPIqJGwRfR+gMqilIfbEQRxArqm4l9a6AzR27ZDZNkN/Ozm92S2QvzsnO+853vnjvn/i2hYI0KphdDwZs948MMDzO8yXpgOKQ3WULXyBlmeJjhnD1QrVavdRxnGxFtA2CfawBcEnus5/9izx8ATorIz67r/lIul//MSb0urKdDWmt9MREdFJGDAG7vMuATRDQrIrPMvNSlr2V4TwTX6/XLRKQMwIq9vlfBWT9E9FsURW+7rmvK5fK/3fruSrDv+6WRkREr1D7XdRtMAv53APWFhYW67/vn8nLlFhwEwc1E9BKAm/KS58T9CGCCmT/Jg88l2BizT0Q+y0PYK4yIPOR53itZ/WUWbIxRIqKzEm2Q/ZPM/HQW35kEB0HgE9HxLAQbbSsi93ue92ZantSCjTGPi8gzaR2vY2enmHkAlzfn5q5dEtEBpdS7aRylEmyMsdPNTBqHHWxeBfCViJzyPO9ky6ZWq13hOM4OEdlORI81OyEXTVrRiYKr1eoW13W/ALAlVyTAfmaeS8I2Go2tYRi+DGBPkm2790T0t13sKKV+XQ+fKFhr/RqAB3ME8Z0NPusqqZuiSEQfKKXuzi1Ya22FWsFZ2zlmvmg1qDl37xGRqxzHORtF0fzi4uKc7/tn47Za60cAvJCV1NqLyBOe53WsNR0zrLW+2n53OYfyEWaeagVsfdmpjIjuaiPiDIBjzFyPvwuC4D0i2p9HtOu6t46Njf3Qduh3cqi1fg7A0RyEU8x8pIWbnJzcWiqVPk5aehLRs0qpiRau0WjcGIbhNwAuzRoDEb2ulGr7GbbNcK1W2+k4zrcA3KxkpVJpx+jo6E+x7H4J4LY0fqIoOlSpVN5q2RpjZpo7rzTwFTYiss/zPFtsV7S2goMgmLHbvMwswBlmtnve8y0IggNE9E4GP/PMbPfO55vW+hiApzLgl01F5H3P8+5JFKy1tvvYT/OQAPiamXfHAn4DwKEsvohou1LqlMXU6/U7oyj6KAt+le0dqzcZazIcBMHDRLRccDKSvcjMozHBtnDcksWHLWxKqQ8tZmpq6sqlpaV/suDjtkTESimz4rfVzgonuHBDulls8s6BF1LRmvU8777EotUUvJeIPs/z7Vwg01IYRdGuSqWyZvHRcaVljJkWkQdyiB74wgPA88z8aLvYOwpuNBo7wzD8PodgCxnk0vI0gN3M/Fcmwc2hPUFEmY5QmiQD2zwAOMzM050Slbg9NMbMiUi7RX9S8vu+PQQwzcyH1wssjeAbAJywW7okhR3e9+UAAMDpMAz3jo+P2yHdsSUKtshCHfG0uqpQh3gx0cdFxM85tDcEtmHHtDHRxTmIj4kuzlVLS3ShLtNaogt1XRqvQPZCPAxD5TjOvZv6Qnx12S3MXx7azTeF+FPLhky0PXaaamnZY86BuhsKHmj394F8mOE+dPJAKYYZHmj394F8mOE+dPJAKQqX4f8BYPRTW5RGL5kAAAAASUVORK5CYII="
                      alt=""><span>94332.5k</span><img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAABOBJREFUaEPtmmtoHFUUx///2SiKQdsKRqUoBfWD+kGREltRqlZBK6LQRLRaK4So+ZLt3DtJfEEQIjRzZ1O1vioSXwS0KC3EkrZY+sUg2hoQRNT6QFFo0agItWqyR2aSLNvsc3ZnNskm9+PuOed/fveeO3PPzBCLbHCR8WIJuN5XfGmFl1a4zmag4pIW110HYB3IJlCapubFOgCRw9T606jmSXp7T8c5Z90C4VoImyByPogTAL+AJd9BrC9p24fL1QsFLMZcAwvtENwF4LwiIkcg2Emtd5abyGw7GRw8A+PjfYBsAbCieBwOQeRNar2vlF7ZwJIyr0DQXirgrP+PAOleqq7hMH5B9VjsA7A2jB+A16n0Q8V8ygIWz7wLoCWkeJY5+6jUk+X4i+d2AwFsohz7PDY/UOlVhXxLAlcPOy1NvA9Y99O2/y6UjKTMOxC0Vgia7XaUSl+aL05R4MhgM8qyF0w8QNsez9mznhkE4O/XqMYuKp0zeQWBxXNfBvhwVOqZOMRBwNpM2/555jcx5gUQHZFrCZLU+tnsuHmBJZW6ApIeA3Ba5ElMBRwFrQdp20fFc12AOiad75GWa+k4x2fi5wf2XA+gHVMSM2HHABkG+FS8OuygUi8VBBbXXQWLnwFYFm8itYou71E5GwsDe+7T8c96rWADnd+pdObgklPS4plPAKyuaUpxi1mJ5dy69Q9fJh/wsRLHxrjTiz5+Q/oydnZ9kwMsO3aci39O/hq94lxH5HVUajQXeKD/KqQt/3ZUX0NwN7XenQvseXcCsqe+aH0aaadyXs0FNqYTxPa6AyaeoK2fyQVOuQ6E/fUHLPfQdvyO79SrtHj9WwDLP8TX10g0XMxk8sd8e/h2QD6oK1rBt9T6krwnLUmlVkDSv9UVMDhEpTblBQ6uZ54ZAnBv3UDPahFzT1rGbALxdt0Ac7KZdrd/XA5G/vbQmI9BNNcB9AEqfWs2R4F+uH8zYL2x8IHTHVRdmV644ApP7+WDAG5cwNDjSDRczmTSb4Yyo/AzrQGzEWnsWrjA8hqV0zY7/+JPLVNmGIINCxOaG6jU3nDArrsGFkcAnL2woLmNSvXky7n0g3hj2kAEncaCGMQhrLxoPVtbJysCDi5gxmwH0TnvgUVOgtbNM81+5cAtLQk0N4+AWD+/oUVROaliOZYs6Rlncd0r4b8fIvO+s5n7ieBzVKpkFZYNHJS2D21xP4AL5h7wlJvrIdq6rDNDKOAA+vltF+LfxFcAGucN9H8Ty9jT82c5+YQGDqBTqTMh6RPlCMRuQ6uZtp1pDkrpVQSc2dfG/QnkylIisf1Pa3WY7zv8PKoCnlptMwrBmtig8gc+jkTDDUwm/a0ValQNHEBX/UlEqJzHMDF5B7u7fwnlNW0cCXANoYcxMXkfu7v/qgQ2kpLOFhbPfRHgo5UmU9xPDJXjVBs7shXOXMgGTBsmpR/k8mqTm/Y/BoFDrd+KIl7kwEF5+x+wAf0gbqoqSXI/rLTDpPN5VXGynGMBDqB7ey00NvrQqrJkoynh2dqxAWdK3JjbQD4GyPXlgcsIYBkq9WF59uGsYgfOgHueD/14kSPp1wB90Fh775oBT926vKtBeSTrm03/XvoRKPuQ5h5qHfvL+JoChyu+eKyXgOOZ1/kTdWmF589axJPJolvh/wF8iXZMtgf/PgAAAABJRU5ErkJggg=="
                      alt=""><span>90.1k</span></div>
                </div>
                <div class="right">
                  <div class="avatar"><img :src="v.img_url" alt=""></div>
                </div>
              </li>
            </ul>
          </div>
          <!-- <div class="movie_list_n" v-if="movielist_1.length > 0">
          <div class="movie-list-n-item" :style="{backgroundImage:`url(${v.img_url})`}" v-for="(v,key) in movielist_1"
            :key="key" @click="toPlayVideo(v.id)">
            <div class="movie-list-n-item-bottom">

              <div class="movie-list-n-lab" v-if="v.one">{{v.one}}</div>
              <div class="movie-list-n-lab" v-if="v.two">{{v.two}}</div>
              <div class="movie-list-n-lab" v-if="v.three">{{v.three}}</div>
              <div class="movie-list-n-title">
                {{v.xuanfei_name}}
              </div>
              <div class="movie-n-time-div">
                <div class="movie-list-money">
                  {{v.price}}</div>
                <div class="movie-list-addr">
                  <van-icon name="location-o" />
                  {{v.address}}
                </div>

              </div>
            </div>
          </div>
        </div> -->
        </van-list>

      </van-pull-refresh>
    </div>
    <van-popup v-model="pop_content" round closeable close-icon="close"
      style="width:70%;height: 50%;font-size: 24px;color: #000;">
      <div class="pop_content-content" v-html="pop_content_txt"></div>


    </van-popup>

    <van-popup v-model="addr_popup" round close-icon="close"
      style="width:70%;background: none;">
      <ul class="cityPopup">
        <template v-if="viodeLang == 'ja_ja'">
          <li><i class="cubeic-like"></i> 東京です</li>
          <li @click="openAddr">大阪です</li>
          <li @click="openAddr">神奈川県です</li>
          <li @click="openAddr">京都です</li>
          <li @click="openAddr">沖縄です</li>
          <li @click="openAddr">福岡県です</li>
          <li @click="openAddr">北海道です</li>
          <li @click="openAddr">横浜です</li>
        </template>
        <template v-else>
          <li><i class="cubeic-like"></i> 인기 지역</li>
          <li @click="openAddr">서울</li>
          <li @click="openAddr">부산</li>
          <li @click="openAddr">대구</li>
          <li @click="openAddr">인천</li>
          <li @click="openAddr">광주</li>
          <li @click="openAddr">대전</li>
          <li @click="openAddr">울산</li>
          <li @click="openAddr">제주도</li>
          <li @click="openAddr">전주</li>
          <li @click="openAddr"><span>기타 지역</span></li>
        </template>
      </ul>


    </van-popup>

    <img class="luckDraw" v-if="isluckdraw == '1'" @click="toLuckDraw" src="@/assets/luckdraw.png" alt="">
    <img class="kefu" v-if="iskefu == '1'" src="@/assets/kefu.png" @click="toKefu" alt="" srcset="">
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [],
      loading: false,
      finished: false,
      isLoading: true,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      },
      video_login: this.$store.getters.getBaseInfo.video_login || 1,
      token: localStorage.getItem('token'),
      isluckdraw: null,
      pop_content_txt: '',
      pop_content: false,
      kefu: '',
      iskefu: 0,

      coordinate: {
        client: {},
        elePosition: {}
      },
      page: 1,
      tabs: 0,
      addr_popup: false,
      viodeLang:localStorage.getItem("viodeLang")|| ''

    };
  },
  methods: {
    openAddr() {
      if(this.viodeLang == 'ja_ja'){

        this.$toast.loading({
        message: ' 管理者に連絡してアカウントを有効にします',
        forbidClick: true,
      });
      }else{
        
      this.$toast.loading({
        message: ' 관리자에게 연락하여 계정을 활성화하십시오',
        forbidClick: true,
      });
      }
      this.addr_popup = false
    },
    getxuanfeilist() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { id: this.$route.query.id }
      }).then(res => {
        this.datalist = res.data;
      });
    },
    toKefu() {

      window.location.href = this.kefu
    },
    touchStartHandle(refName, e) {
      let element = e.targetTouches[0]
      // 记录点击的坐标
      this.coordinate.client = {
        x: element.clientX,
        y: element.clientY
      }
      // 记录需要移动的元素坐标
      this.coordinate.elePosition.left = this.$refs[refName].offsetLeft
      this.coordinate.elePosition.top = this.$refs[refName].offsetTop
    },
    drag(refName, e) {
      let element = e.targetTouches[0]
      // 根据初始client位置计算移动距离（元素移动位置+光标移动后的位置-光标点击时的初始位置）
      let x = this.coordinate.elePosition.left + (element.clientX - this.coordinate.client.x)
      let y = this.coordinate.elePosition.top + (element.clientY - this.coordinate.client.y)
      // 限制可移动距离，不超出可视区域
      x = x <= 0 ? 0 : x >= innerWidth - this.$refs[refName].offsetWidth ? innerWidth - this.$refs[refName].offsetWidth : x
      // 50是头部的高度
      y = y <= 50 ? 50 : x >= innerHeight - this.$refs[refName].offsetHeight ? innerHeight - this.$refs[refName].offsetHeight : y
      // 移动当前元素
      this.$refs[refName].style.left = x + 'px'
      this.$refs[refName].style.top = y + 'px'
    },
    toLuckDraw() {
      this.$router.push({ path: '/luckDraw' })
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
        }
      }

    },
    // toPlayVideo(id) {

    //   if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
    //     this.$router.push({path:'/Login'})
    //   } else {
    //     if (id) {
    //      this.$router.push({path:'/PlayVideo?id='+id})
    //     }
    //   }
    // },

    toPlayVideo(id) {

      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/nHomeDetail?id=' + id })
        }
      }
    },
    // onRefresh() {
    //   setTimeout(() => {
    //     this.getBasicConfig();
    //     this.isLoading = false;
    //     this.$toast(this.$t('reservation.refresh'));
    //   }, 500);
    // },

    onRefresh() {
      setTimeout(() => {
        this.page = 1
        // this.movielist_1 = []
        this.getNhomeList();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    onLoad() {
      this.loading = true;
      this.getNhomeList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        // this.getMovieList_1(this.basicData);//获取首页视频1

        this.$nextTick(() => {
          this.lazyLoadImg()
        })
      })

    },
    getNotice(data) {
      this.notice = data.notice || '';
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      this.banners = data.banners;
    },
    query(selector) {
      return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
        this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    },

    getNhomeList() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { page: this.page },
      }).then(res => {
        console.log(res.data, 999)
        this.loading = false;
        if (this.page == 1) {
          this.movielist_1 = res.data;
        } else {

          this.movielist_1 = this.movielist_1.concat(res.data);
        }
        if (res.data.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          return this.$router.push({ path: '/Login' })
        }
        this.getNhomeList()
        console.log(res)
        this.isLoading = false
        this.isluckdraw = res.data.isluckdraw
        this.pop_content_txt = res.data.pop_content
        this.kefu = res.data.kefu
        this.iskefu = res.data.iskefu
        if (res.data.ispop == 1) {
          this.pop_content = true
        }


        this.$store.commit('setBaseInfoValue', res.data);
        this.getBasicConfig();
      }
    })
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
  height: 200px;
  display: flex;
  align-items: center;
  background: linear-gradient(270deg,  #f43443, #f43443);
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1b212b;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  margin-top: 4%;
  // margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div>div:first-child {
  width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #e6c3a1;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #e6c3a1;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #e6c3a1;
}

.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #f43443;
  border-radius: 1px;
  margin-right: 5px;
}

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 24px;
  color: #e6c3a1;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #1b212b;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
    height: 330px;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: 10px;
  width: 335px;
  height: 290px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 10px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #e6c3a1;
  font-size: 30px;
}

.hot-items-div {
  padding: 12px 0;
}

.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.luckDraw {
  position: fixed;
  bottom: 160px;
  right: 40px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  z-index: 999;
}

.kefu {
  position: fixed;
  bottom: 300px;
  right: 60px;
  width: 80px;
  height: 80px;
  z-index: 999;
  // background: #fff;border-radius: 50px;
}

/deep/.van-popup__close-icon {
  font-size: 50px;
}

.pop_content-content {
  font-size: 30px;
  color: #1c1e23;
  padding: 30px;
  margin-top: 40px;
}





.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.bd-info .info-model .model-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: #8f65fe;
  border-radius: 0.5rem;
  margin: 0.625rem;
  padding: 0.625rem;
}

.bd-info .info-model .model-item .item-pic {
  position: relative;
  width: 7.5rem;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 0.625rem;
}

.bd-info .info-model .model-item .item-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: .75rem;
}

.bd-info .info-model .model-item .item-pic img {
  width: 7.5rem;
  height: 11.25rem;
  border: 0;
  vertical-align: middle;
}

.bd-info .info-model .model-item .item-info .name {
  margin-bottom: 0.625rem;
  margin-top: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag span {
  font-size: .75rem;
  color: #fff;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  background-color: #ea92cf;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .info {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .remark {
  // height: 2.625rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 0.75rem;

  display: -webkit-box;

  overflow: hidden;

  white-space: normal !important;

  text-overflow: ellipsis;

  word-wrap: break-word;

  -webkit-line-clamp: 3;

  -webkit-box-orient: vertical
}

.bd-info .info-model .model-item .item-info .call .van-button {
  width: 100%;
  height: 2.125rem;
  border-radius: 6.1875rem !important;
}

.bd-info .info-model .model-item .item-info .remark span {
  color: #ddd2fb;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .name span {
  display: inline-block;
  color: #fff;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .info span {
  color: #ddd2fb;
  font-size: .875rem;
}

.van-button--info,
.van-button--primary {
  border: none !important;
  background: -webkit-gradient(linear, left top, right top, from(#a082f0), to(#ea92cf)) !important;
  background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
  border-radius: 5px !important;
}

.movie_list_n {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding-bottom: 120px;
}

.movie-list-n-item {
  width: calc(50% - 30px);
  padding: 10px;
  background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  margin: 15px 15px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  height: 480px;
  box-shadow: 0 0 10px 4px rgba(202, 202, 202, 0.6);
  border: none;

}

.movie-list-n-item-top {
  padding-top: 40px;
}

.movie-list-n-item-bottom {
  color: #fff;
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  padding-left: 10px;

}

.movie-n-time-div {
  margin-top: 20px;
}

.movie-list-n-title {
  // color: #f905e5;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2;

  font-size: 28px;
  margin: 12px 0 12px;
  line-height: 1.4;
  height: 80px;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.movie-list-n-lab {
  color: #ffffff;
  background: #7f7f7f;
  border-color: #00b300;
  font-size: 18px;
  padding: 8px;
  margin-right: 4px;
  display: inline-block;
}

.movie-list-ico-loves {
  display: inline-block;
  width: 40px;
}


.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-notice {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

.movie-list-addr {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 28px;
}

.movie-list-ico-addr {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

@keyframes rainbow_animation {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

.movie-list-money {
  background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  animation: rainbow_animation 10s ease-in-out infinite;
  background-size: 400% 100%;
  font-weight: bold;
  position: relative;
  margin-right: 10px;
}

.movie-list-ico-money {
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}

.appname {
  font-size: 32px;
  color: #ffffff;
}



.noticeContent {
  padding: 30px
}

.sm {
  width: 100%;
  min-height: 1rem;
  border-radius: 1.333vw;
  border: 1px solid #766350;
  margin: 1.333vw 0;
  background: linear-gradient(270deg, #2e3846, #f43443);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.333vw;
  position: relative;
  overflow: hidden
}

.sm:after,
.sm:before {
  content: "";
  position: absolute;
  width: 137.4vw;
  height: 19.2vw;
  background: linear-gradient(0deg, rgba(222, 213, 162, .29), transparent);
  transform: rotate(139deg)
}

.sm:before {
  left: -4%
}

.sm:after {
  left: -35%;
  z-index: 0
}

.sm>div {
  position: relative;
  z-index: 99;
  display: flex
}

.sm .left {
  width: 32%;
  position: relative;
  z-index: 99
}

.sm .left p {
  font-weight: 1000;
  background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4.267vw;
  text-align: center;
  margin-top: 3vw
}

.sm .right {
  flex: 1;
  padding-left: 1.333vw
}

.sm .right p {
  color: #dadada;
  font-size: 3vw;
  line-height: 6.4vw
}

.sm>span {
  line-height: 5.067vw
}

.sm .left span,
.sm>span {
  color: #ffffff;
  font-size: 3vw;
  padding-top: 1.333vw;
  text-align: center
}

.sm .left span {
  line-height: 3.2vw
}

.sm .left:before {
  content: "";
  position: absolute;
  width: .02rem;
  height: 100%;
  background: #f6d5a1;
  right: 0;
  top: 0
}

.navs>nav {
  padding-top: 2vw;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap
}

.navs>nav a {
  font-size: 1.867vw;
  padding: 1.333vw 2vw;
  border-radius: 6.667vw;
  margin-right: 1.867vw;
  background: linear-gradient(90deg, #5b606c, #2b2b2b);
  color: #fff;
  margin-bottom: 1.333vw
}

.navs>nav a.active {
  background: linear-gradient(#f43443, #f43443);
  color: #ffffff
}



.item ul li {
  float: left;
  width: 100%;
  margin-top: 1.333vw;
  min-height: 1.867vw;
  background: linear-gradient(270deg, #2e3846, #f43443);
  position: relative;
  overflow: hidden;
  margin-bottom: 1.333vw;
  border-radius: 1.333vw;
  border: 1px solid #7e6a56;
  padding: 1.333vw;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.item ul li:after,
.item ul li:before {
  content: "";
  position: absolute;
  top: -100%;
  height: 400%;
  background: linear-gradient(90deg, hsla(0, 18%, 88%, .1), transparent);
  transform: rotate(50deg)
}

.item ul li:before {
  width: 8vw;
  left: 1%
}

.item ul li:after {
  width: 13.333vw;
  left: 40%
}

.navs>nav a {
  font-size: 1.867vw;
  padding: 1.333vw 2vw;
  border-radius: 6.667vw;
  margin-right: 1.867vw;
  background: linear-gradient(90deg, #5b606c, #2b2b2b);
  color: #fff;
  margin-bottom: 1.333vw
}

.navs>nav a.active {
  background: linear-gradient(#f43443, #f43443);
  color: #ffffff
}

.item ul li i {
  position: absolute;
  right: -2.333vw;
  top: 2.667vw;
  background: #ff6056;
  font-size: 2.133vw;
  padding: .533vw 2.667vw;
  font-style: normal;
  transform: rotate(45deg);
  color: #e7e7e7
}

.item ul li .left .ft img {
  width: 3.2vw;
  margin-right: .267vw
}

.item ul li .left .ft span {
  font-size: 1.6vw;
  color: #ffffff;
  margin-right: .8vw
}

.item ul li .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start
}

.item ul li .left p {
  background: linear-gradient(90deg, #efcba7, #dcab76);
  color: #8d5825;
  display: inline-block;
  font-size: 1.6vw;
  font-weight: 600;
  padding: 2.667vw 2.4vw;
  border-radius: 1.333vw;
  margin-bottom: 1.333vw;
  margin-top: 2.133vw
}

.item ul li .left .ziliao {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 3.733vw;
  margin-left: 1.333vw
}

.item ul li .left .ziliao span {
  padding: .4vw 0;
  color: #e8e8e8
}

ul li .left .ft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1.333vw
}

.item ul li .right {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

.item ul li .right .avatar {
  width: 28vw;
  height: 28vw;
  border-radius: 50%;
  border: .267vw solid #5c534b;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.item ul li .right .avatar img {
  width: 100%
}

.item.tiaokuan ul li {
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.item.tiaokuan ul li p {
  color: #e1be9c;
  background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.733vw;
  font-weight: 600;
  text-align: left;
  padding: 1.333vw 0
}

.item.tiaokuan ul li span {
  font-size: 3vw;
  color: #fff
}

.item {
  padding: 0 30px;
}


.item.tiaokuan ul li {
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

.item.tiaokuan ul li p {
  color: #e1be9c;
  background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.733vw;
  font-weight: 600;
  text-align: left;
  padding: 1.333vw 0
}

.item.tiaokuan ul li span {
  font-size: 3vw;
  color: #fff
}


.cityPopup {
    width: 88%;
    margin: 0 auto;
    padding: 2.667vw;
    border: 1px solid #7e6a56;
    border-radius: .8vw;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: center;
    background: linear-gradient(120deg,#212121,#313131);
    overflow: hidden
}

.cityPopup li:first-child {
    color: #f54c48
}

.home-container .cityPopup li:first-child {
    width: 100%
}

.cityPopup li:first-child .cubeic-like {
    font-size: 2.667vw
}

.cityPopup li {
    width: 33%;
    flex-shrink: 0;
    padding: 1.333vw 0;
    color: #fff;
    word-break: break-all
}

.cityPopup li:last-child {
    margin-top: 1.333vw;
    width: 100%
}

.cityPopup li:last-child span {
    padding: .533vw 1.867vw;
    border-radius: 3.2vw;
    color: #8a5623;
    font-size: 3.2vw;
    background: linear-gradient(#fde3ca,#e7b486);
    display: block;
    margin: 0 auto;
    width: 45%
}
::v-deep .van-toast__text {
  font-size: 24px;
}
::v-deep .van-toast{
  padding: 0;
}
</style>
